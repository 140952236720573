<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>{{ getCommonText('budget') }}</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                :header="header"
                :isParentGuExist="isParentGuExist"
                :isHeadGu="isHeadGu"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
                @downloadBatchReportsPaid="downloadBatchReportsPaid"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
            </div>
            <div class="right-content">
                <div class="filter-actions filter-actions-flex">
                    <c-budget-forms-copy-data 
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                        :budgetForm="budgetForm"
                        :header="header"
                        :isLoad="isLoad"
                        @keyPress="keyPress"
                    />
                    <b-button :style="{ 'minWidth': '110px' }" variant="success" @click="prepareForSave" :disabled="isLoad || !variantAttribute || load">{{ getCommonText('save') }}</b-button>
                </div>
            </div>
        </div>

        <breadcrumbs-filter :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                no-border-collapse
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <td class="td-numbering table-success">1</td>
                    <td class="td-numbering table-danger">2</td>
                    <td class="td-numbering table-info">3</td>
                    <td class="td-numbering table-primary">4</td>
                    <td class="td-numbering table-warning">5</td>
                    <td class="td-numbering table-success">6</td>
                    <td class="td-numbering table-danger">7</td>
                    <td class="td-numbering table-info">8</td>
                    <td class="td-numbering"></td>
                </template>
                <template #head(action)>
                    <div class="text-center">
                        <b-form-checkbox 
                            v-model="selectAll"
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i 
                            :title="getCommonText('del_selected')" 
                            class="icon icon-close table-all-remove"
                            @click="deleteItem(`${selectAll ? getCommonText('clear_all') :  getCommonText('clear')}`)"/>
                    </div>
                </template>

                <template #cell(action)="data">
                    <b-form-checkbox 
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(average_meals)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.average_meals"
                                  @change="v => data.item.average_meals = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="onBlur(data.item, 'average_meals', data.item.average_meals, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                    <p class="budget-form-val-msg" v-if="variantAttribute && data.item.showValidationError && !data.item.average_meals > 0">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(func_day)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.func_day"
                                  @change="v => data.item.func_day = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="onBlur(data.item, 'func_day', data.item.func_day, 0)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                    <p class="budget-form-val-msg" v-if="variantAttribute && data.item.showValidationError && !data.item.func_day > 0">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(correction_factors)="data">
                    <b-form-input v-if="variantAttribute"
                                 :disabled="data.item.average_meals <= 0 || !data.item.average_meals || data.item.func_day <= 0 || !data.item.func_day"
                                  class="text-right"
                                  :value="data.item.correction_factors"
                                  @change="v => data.item.correction_factors = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\-?\\d{0,9}$')"
                                  @blur="onBlur(data.item, 'correction_factors', data.item.correction_factors, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell()="data">
                    <div class="text-right">
                        {{ ((isNaN(data.value) || (data.value == 0)) ? 0 : $n(data.value)) || data.value }}
                    </div>
                </template>
                <template #cell(order_num)="data">
                    <div class="text-center">
                        {{ ((isNaN(data.value) || (data.value == 0)) ? 0 : $n(data.value)) || data.value }}
                    </div>
                </template>
                <template #cell(recipient)="data">
                    <div class="text-left">
                        {{ ((isNaN(data.value) || (data.value == 0)) ? 0 : $n(data.value)) || data.value }}
                    </div>
                </template>
                <template #cell(note)="data">
                    <div v-if="variantAttribute" :class="{ 'error': ((data.item.note && data.item.note.length > 255)
                    || (data.item.correction_factors !== 0 && data.item.note.trim().length === 0)) }">
                        <div class="note-box">
                            <b-form-input
                                class="text-right"
                                style="min-width: 90%"
                                :value="data.item.note"
                                @change="v => data.item.note = v"
                                @keyup.enter.exact="keyup13">
                            </b-form-input>
                            <i v-if="variantAttribute"
                                class="icon icon-more thin-blue pointer icon-18 icon-rotate-90" 
                                @click="onEditNote(data.item)">
                            </i>
                        </div>
                        <template v-if="(data.item.note && data.item.note.length > 255)">
                            <i class="icon icon-danger"></i>
                            <div class="pop-up">
                                <p class="red-text">{{ getCommonText("not_valid") }}</p>
                                <p>{{ getCommonText("len_limit_255") }}</p>
                            </div>
                        </template>
                        <template v-else-if="(data.item.correction_factors !== 0 && data.item.note.trim().length === 0)">
                            <i class="icon icon-danger"></i>
                            <div class="pop-up">
                                <p class="red-text">{{ getCommonText("not_valid") }}</p>
                                <p>{{ getCommonText("required") }}</p>
                            </div>
                        </template>
                    </div>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="onFilesClick(data.item)"
                    >({{data.item.files}})</span>
                </template>
                <template #cell(more)="data">
                    <div class="text-center">
                        <i 
                            :title="getCommonText('del_rec')" 
                            class="icon icon-clear table-remove" 
                            v-if="variantAttribute" 
                            @click="deleteItem(`${getCommonText('clear_rec')}`, data.item, data.index)"
                        ></i>
                    </div>
                </template>
                <template #bottom-row="data">
                    <td class="text-right" colspan="6">{{ getCommonText('total') }}</td>
                    <td class="text-right">{{ isNaN(total) ? 0 : $n(total) }}</td>
                    <td colspan="3"></td>
                </template>
            </b-table>
        </div>
        <files-updown :header="header"
                      :variant-attribute="variantAttribute"
                      :load="load"
                      @getFiles="getFiles"
                      @getNewFiles="getNewFiles($event)"
                      @delFile="delFile($event)"
                      ref="fileUpdown"
        ></files-updown>
        <modal-files-management-nodecode
            ref="modalFilesManagement"
            :variant-attribute="variantAttribute"
            @triggDownloadFile="triggDownloadFile"
            @triggDownloadAllFiles="triggDownloadAllFiles"
            @fileUpload="fileUpload"
            @toggleIsAdd="toggleIsAdd($event)"
        />
        <modal-note
            ref="setNoteModal"
            :noteFieldName="noteFieldName"
            :getCommonText="getCommonText"
            :onEditNote="onEditNote"
        />
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import ModalFilesManagementNodecode from './components/modal-files-management-nodecode.vue';
import FormsHandlerMixin1 from "./mixins/forms-handler-mixin-1";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";
import ModalNote from './components/modal-note.vue';

export default {
    name: 'Form04_141',
    components: { 
        BudgetHeader, 
        FilesUpdown, 
        BudgetFormsList, 
        FormsDownloadReprt, 
        BreadcrumbsFilter, 
        ModalFilesManagementNodecode, 
        CBudgetFormsCopyData,
        ModalNote
    },
    mixins: [FormsHandlerMixin1],
    data() {
        return {
            form: {
                code: '04-141',
                name_kk: 'Денсаулық сақтау мекемелерiндегi тамақтануға арналған шығыстарды есептеу',
                name_ru: 'Расчет расходов на питание в учреждениях здравоохранения'
            },
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            dictDivision: [],
            header: null,
            files: null,
            dict: null,
            load: true,
            openDisabled: false,
            isLoad: false,
            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: -1,
            noteFieldName: 'note'
        };
    },
    async mounted() {
        await this.loadDictDivision();
    },
    methods: {
        async loadDictDivision() {
            try {
                const response = await fetch('/api-py/dictionary/division_names/');
                this.dict = await response.json();
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDictDivision`, error.toString());
            }
        },
        async loadDatas() {
            const that = this;

            that.load = true;
            await that.budgetForm.splice(0);
            for (const item of that.dict) {
                if (item.order_num == null) {
                    item.order_num = '';
                }
                const dataItem = {
                    id: this.newRowStartId,
                    code: item.code,
                    order_num: item.order_num,
                    average_meals: 0,
                    func_day: 0,
                    correction_factors: 0,
                    note: ''
                };
                Object.defineProperty(dataItem, 'recipient', {
                    get: () => {
                        return item[`name_${this.lng}`]
                    }
                })
                Object.defineProperty(dataItem, 'areRequiredFieldsFilled', {
                    get: () => {
                        return dataItem.average_meals > 0 && dataItem.func_day > 0;
                    }
                });
                Object.defineProperty(dataItem, 'isCorrectionFactorsFilled', {
                    get: () => {
                        return dataItem.correction_factors !== 0 && dataItem.correction_factors;
                    }
                });


                this.newRowStartId--;

                this.$set(dataItem, 'files', 0);

                try {
                    this.load = true;
                    const response = await fetch(`/api-py/get-new-row-files-form/${dataItem.id}/${JSON.stringify(this.header)}`);
                    const emptyRowFiles = await response.json();
                    this.$set(dataItem, 'row_files', emptyRowFiles);
                } catch (error) {
                    this.makeToast('danger', `${this.getErrText('bad_request')} itemUpdate`, error.toString());
                }

                Object.defineProperty(dataItem, 'total', {
                    get: function () {
                        const sum = (that.number(dataItem.average_meals) * that.number(dataItem.func_day) / 1000) + parseFloat(dataItem.correction_factors);
                        return parseFloat((Math.round(sum * 100) / 100).toFixed(2));
                    }
                });
                that.budgetForm.push(dataItem);
            }

            let values = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(that.header));
                values = await response.json();
                if (response.status === 200) {
                    if (values.length === 0) {
                        that.load = false;
                        return;
                    }
                } else {
                    this.budgetForm.splice(0);
                    this.makeToast('danger', `${this.getErrText('bad_request')} loadDatas()`, `Error code: ${response.status}`);
                }
            } catch (error) {
                this.budgetForm.splice(0);
                that.makeToast('danger', `${this.getErrText('bad_request')} loadDatas()`, error.toString());
            }

            await values.forEach(val => {
                const itemExist = that.budgetForm.filter(function (item) {
                    if (item.code === val.recipient) { return item; }
                });

                if (itemExist.length > 0) {
                    itemExist[0].id = val.id;
                    itemExist[0].average_meals = val.average_meals;
                    itemExist[0].func_day = val.func_day;
                    val.correction_factors ? itemExist[0].correction_factors = val.correction_factors : itemExist[0].correction_factors = 0;
                    itemExist[0].note = val.note;
                    itemExist[0].files = val.files;
                    itemExist[0].row_files = val.row_files;
                }
            });
            that.load = false;
        },

        onBlur(item, fieldName, value, rank) {
            this.inputFixedVldtn(item, fieldName, value, rank);
            this.$nextTick(() => {
                this.resetExpensesAmount(item, fieldName);
                if (item.showValidationError) {
                    if ((item.func_day > 0 && item.average_meals > 0) 
                        || (item.func_day === 0 && item.average_meals === 0)) {
                        delete item.showValidationError;
                    }
                }
            });    
        },

        resetExpensesAmount(item, fieldName) {
            if (['func_day', 'average_meals'].includes(fieldName) && item[fieldName] === 0) {
                const correctionFactors = 'correction_factors';
                if (correctionFactors in item) {
                    this.$set(item, correctionFactors, 0);
                }
            }
        },

        async loadSpecificData() {
            await this.loadDictDivision();
        },
        
        async delete(item) {
            this.$set(item, 'form', this.form.code);
            try {
                this.$set(this.header, 'value', this.calcTotalForDelete(this.budgetForm, 'total'));
                const response = await fetch('/api-py/delete-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header), {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(item)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('one_deleted'));
                    this.deletingAgreementTotalResultHandler(result);
                }
            } catch {
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('on_del'));
            }
        }, // удаление данных

        deleteItem(msg, row = false 
                || (!row && this.budgetForm.findIndex(itm => itm.itemToDelete) === -1)) {
            if (!this.variantAttribute || this.isLoad) return;
            this.$bvModal.msgBoxConfirm(
                msg,
                {
                    title: this.getCommonText('confirm'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: this.getCommonText('yes'),
                    cancelTitle: this.getCommonText('cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        if (row) {
                            row.average_meals = 0;
                            row.correction_factors = 0;
                            row.func_day = 0;
                            row.note = '';
                        } else {
                            this.budgetForm.forEach(item => {
                                if (item.itemToDelete) {
                                    item.average_meals = 0;
                                    item.correction_factors = 0;
                                    item.func_day = 0;
                                    item.note = '';
                                };
                            });
                        }
                        this.prepareForSave();
                    }
                })
                .catch(error => {
                    this.makeToast('danger', this.getErrText('on_del'), error.toString());
                });
        },

        itemUpdate(item) {
            this.$set(item, 'recipient', '');
            this.$set(item, 'average_meals', 0);
            this.$set(item, 'func_day', 0);
            this.$set(item, 'correction_factors', 0);
            this.$set(item, 'note', '');
        },
        getFiles(data) {
            this.files = data;
        },

        keyPress: function (event, pattern) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        number(value) {
            return (isNaN(parseFloat(value)) ? 0 : parseFloat(value));
        },

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        prepareForSave() {
            const values = [];
            let error = false;
            const template = (row) => {
                const item = Object.assign({}, this.header);
                this.$set(item, 'id', row.id);
                this.$set(item, 'recipient', row.code);
                this.$set(item, 'average_meals', parseFloat(row.average_meals));
                this.$set(item, 'func_day', parseInt(row.func_day));
                this.$set(item, 'correction_factors', parseFloat(row.correction_factors));
                this.$set(item, 'note', row.note);
                this.$set(item, 'row_files', row.row_files);
                this.$set(item, 'total', row.total);
                values.push(item);
            }
            for (const row of this.budgetForm) {
                const isNewRecord = row.id < 0;
                const isCorrectionFactorsFilled = row.isCorrectionFactorsFilled;
                const areRequiredFieldsFilled = row.areRequiredFieldsFilled;
                const isOnlyOneRequiredFieldFilled = !areRequiredFieldsFilled && (row.average_meals > 0 || row.func_day > 0);
                const isNoteFilled = row.note.trim().length > 0 && row.note.length < 256;

                if (isNewRecord && !areRequiredFieldsFilled && !isOnlyOneRequiredFieldFilled) {
                    continue;
                }
                

                if (isOnlyOneRequiredFieldFilled 
                    || (isCorrectionFactorsFilled && !isNoteFilled)
                    || (!areRequiredFieldsFilled && isCorrectionFactorsFilled)
                    ) {
                    error = true;
                    if (isOnlyOneRequiredFieldFilled || (!areRequiredFieldsFilled && isCorrectionFactorsFilled)) {
                        this.$set(row, 'showValidationError', true);
                    }
                    continue;
                }

                template(row);
            }
            if (error) {
                this.onFieldsValidationFailed();
                return;
            }
            if (values.length > 0 && this.variantAttribute) {
                this.save(values);
            } else {
                this.makeToast('warning', this.getErrText('msg'), this.getErrText('not_saved'));
            }
        },

        async save(values) {
            this.isLoad = true;
            try {
                const url = this.header.mode === 'gkkp' ? '/api-py/save-brform-gkkp/' : '/api-py/save-brform' + this.form.code + '/'
                const totalToSave = this.totalCalculation(values, 'total')
                this.$set(this.header, 'value', totalToSave);
                const response = await fetch(url + JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (this.files.length === 0) {
                        this.makeToast('warning', this.getErrText('warning'), this.getErrText('no_docs'));
                    }
                    this.selectAll = false;
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('saved'));
                    this.deletingAgreementTotalResultHandler(result);
                    await this.loadDatas();
                } else {
                    this.selectAll = false;
                    await this.loadDatas();
                    throw this.getErrText('bad_data');
                }
            } catch (e) {
                this.makeToast('danger', this.getErrText('warning'), e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных
    },
    computed: {
        total() {
            return this.totalCalculation(this.budgetForm, 'total');
        },

        getFormText() {
            return this.setFormText('form_n_141.');
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'order_num',
                        label: this.getCommonText('sn')
                    },
                    {
                        key: 'recipient',
                        label: this.getFormText('receiver_name')
                    },
                    {
                        key: 'func_day',
                        label: this.getFormText('bed_days')
                    },
                    {
                        key: 'average_meals',
                        label: this.getFormText('bed_day_budget')
                    },
                    {
                        key: 'correction_factors',
                        label: `${this.getFormText('correction_sum')} (+/- ${this.getUnitsText('thousand_tenge')})`
                    },
                    {
                        key: 'total',
                        label: this.getFormText('meal_budget')
                    },
                    {
                        key: 'note',
                        label: this.getCommonText('notes')
                    },
                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    }
                ];
            };
            return [];
        }
    }
};
</script>
<style scoped>
    .filter-actions-flex {
        display: flex;
    }
    .note-box {
        display: inline-flex;
        align-items: center;
        justify-content: space-evenly;
    }
    .icon-more {
        align-self: center;
        position: static;
    }
    .budget-form-val-msg {
        font-size: 0.5rem;
        font-style: italic;
        color: red;
    }
</style>